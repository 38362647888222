@media screen and (max-width: 995px) {
  .side-filters {
    position: absolute;
    top: 49.7rem;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 1000;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  .product-list {
    margin-top: 30%;
    position: static;
  }

  .side-filters.active {
    left: 0;
  }

  .side-filters-overalay {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .product-list {
    margin-top: 80%;
  }
}

@media screen and (max-width: 425px) {
  .product-list {
    margin-top: 65%;
  }
}

.text-wrap {
  white-space: nowrap;
}

.sigCanvas {
  border: 1px solid #9e9e9e;
}

.button-box {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
}

.button-box:hover {
  background-color: transparent;
  border: 1px solid #dc3558;
  color: #dc3558;
}

.side-filters {
  /* width: 400px; */
  z-index: 1000;
  overflow-y: scroll;
  transition: all 0.3s ease-in-out;
}

.side-filters.active {
  left: 0;
}

.side-filters-overalay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0.5;
}

.sidebar-style-new {
  padding: 30px 10px;
}

.sidebar-style-new .sidebar-title {
  font-size: 22px;
  font-weight: 600;
}

.filter-accordion {
  cursor: pointer;
  padding: 15px 5px 15px 10px;
  width: 100%;
  text-align: left;
  outline: none;
  transition: 0.4s;
  font-size: 1.2rem;
  /*   background-color: #ebf9fe;
    margin-bottom: 2px;*/
}

.filter-accordion:after {
  content: "\02795";
  /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.panel-active:after {
  content: "\2796";
  /* Unicode character for "minus" sign (-) */
}

.panel-active,
.filter-accordion:hover {
  color: #dc3558;
}

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom: 1px solid #bbbbbb;
}

.panel p {
  padding: 20px;
  font-size: 1.6rem;
}

.panel ul {
  margin: 40px 0;
}

.panel ul > li {
  font-size: 1.1rem;
  margin-left: 10px;
}

.panel ul > li input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.panel ul > li label {
  cursor: pointer;
}

.sidebar-widget {
  /* border-top: 1px solid #bbbbbb; */
}

.active-filters-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px 15px;
  gap: 0.8rem;
}

.active-filter-item {
  border: 1px solid #dc3558;
  padding: 3px 4px 5px 5px;
  line-height: 1.4;
}

.active-filter-item button {
  transform: translateY(2px);
  margin-left: 5px;
}

.filter-btn {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
  height: 35px;
  width: 120px;
}

.check-disabled {
  pointer-events: none;
  opacity: 0.4;
}

.no-products {
  text-align: center;
  font-size: 1.6rem;
  padding: 20px;
  color: #dc3558;
}

.load-more-btn {
  background-color: #dc3558;
  border: none;
  padding: 5px 10px;
  margin: 2px 5px;
  color: #fff;
  height: 35px;
  width: 120px;
  margin: 0 auto;
  display: block;
}

.overflow-lable {
  height: 150px;
  overflow-y: scroll;
}

.cust-card {
  height: 431px !important;
  display: grid !important;
  align-items: center;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.check-group-resi {
  display: flex;
  align-items: center;
}

.check-group-resi input {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.check-group-resi label {
  cursor: pointer;
  font-size: 15px;
  line-height: normal;
}

.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}

.thankyou-wrapper h1 {
  font: 100px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}

.thankyou-wrapper p {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 5px 10px 10px;
}

.thankyou-wrapper a {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #dc3558;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
}

.thankyou-wrapper a:hover {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #ff1246;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
}

.searched-products {
  background: white;
  position: absolute;
  z-index: 9999;
  width: 25%;
  top: 82px;
  padding: 15px;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: scroll;
}

.searched-products .searched-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

/* customize scroll bar */
.searched-products::-webkit-scrollbar {
  width: 5px;
}

.searched-products::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.searched-products::-webkit-scrollbar-thumb {
  background: #888;
}

.searched-products::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.filter-btn {
  text-align: center;
  color: white;
}

.filter-btn:hover {
  background-color: #fff;
  border: 1px solid #dc3558;
  color: #dc3558;
}

.product-loading {
  display: flex;
  height: 50vh;
  align-items: center;
}

.product-loading div {
  position: absolute;
  top: 50%;
  left: 50%;
}

.active-filter-item span {
  text-transform: capitalize;
}

.state-select {
  font-size: 14px;
  width: 100%;
  height: 45px;
  padding-left: 10px;
  color: #333;
  border: 2px solid #eceff8;
  background: #eceff8;
  box-shadow: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.animate {
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.animate > li {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 100%;
  left: 10px;
  background-color: rgba(255, 0, 0, 0.4);
  z-index: 9999;
  overflow: hidden;
  animation: move 10s linear infinite;
}

.animate > li:nth-last-of-type(2) {
  left: 70px;
  animation-delay: 3.5s;
  height: 15px;
  width: 15px;
}

.animate > li:nth-last-of-type(3) {
  left: 140px;
  animation-delay: 3s;
}

.animate > li:nth-last-of-type(4) {
  left: 210px;
  animation-delay: 5.5s;
}

.animate > li:nth-last-of-type(5) {
  left: 280px;
  animation-delay: 1.8s;
  height: 65px;
  width: 65px;
}

.animate > li:nth-last-of-type(6) {
  left: 140px;
  animation-delay: 6.8s;
  height: 25px;
  width: 25px;
}

.animate > li:nth-last-of-type(7) {
  left: 280px;
  animation-delay: 5s;
  height: 35px;
  width: 35px;
}

@keyframes move {
  to {
    top: -50px;
    transform: rotate(360deg);
  }
}

/* .hero-swiper {
  height: 100vh;
  width: 100%;
  position: relative;
} */

.same-style select {
  padding: 10px 0;
  width: 100%;
  color: #333;
  border-radius: 0;
  background: #fff;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 15px;
}

.same-style select:focus {
  border: 1px solid #dc3558;
}

.box {
  margin: 10px;
  padding-left: 40px;
  text-align: center;
  width: 300px;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #dc3558;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #ff0e42;
  color: #fff;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 7px;
  right: 10px;
  transition: all 200ms;
  font-size: 18px;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #dc3558;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

.same-style a button {
  white-space: nowrap;
}

.cart-item-container {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.cart-item-details {
  margin-left: 10px;
}

.missing-field input {
  border: 1px solid red;
}

.missing-field small {
  color: red;
}

.check-group {
  height: 120px;
  overflow-y: scroll;
}
/*  */
.card {
  margin-bottom: 20px;
}

.card-header {
  border-bottom: 1px solid #ddd;
}

.order-details {
  padding: 15px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
}

.order-details h5 {
  margin-top: 10px;
  font-weight: bold;
}

.order-details p {
  margin: 5px 0;
  padding-left: 15px;
  text-indent: -15px;
}

.order-details .store-total {
  font-weight: bold;
  margin-top: 10px;
}

.order-details .total {
  font-weight: bold;
  margin-top: 20px;
  text-align: right;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.pay-at-store {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border-radius: 50px;
  background-color: #dc3558;
  cursor: pointer;
}

.pay-at-store button {
  background: none !important;
  border: none;
  color: white;
}

.pay-at-store button:hover {
  background: none !important;
  border: none;
  color: white;
}

.pay-at-store:hover {
  background-color: #333;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

:before,
:after {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.v-center {
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

/* header */
.header {
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
  padding: 15px;
}

.header .item-left {
  flex: 0 0 17%;
}

.header .logo a {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
}

/* .header .item-center {
  flex: 0 0 66%;
} */

.header .item-right {
  flex: 0 0 17%;
  display: flex;
  justify-content: flex-end;
}

.header .item-right a {
  text-decoration: none;
  font-size: 16px;
  color: #555555;
  display: inline-block;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.header .menu > ul > li {
  display: inline-block;
  line-height: 50px;
  margin-left: 40px;
}

.header .menu > ul > li > a {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  position: relative;
  text-transform: capitalize;
  transition: color 0.3s ease;
}

.header .menu > ul > li .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #ffffff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
  justify-content: space-between;
}

@media (min-width: 992px) {
  .header .menu > ul > li.menu-item-has-children:hover .sub-menu {
    margin-top: 0;
    visibility: visible;
    opacity: 1;
  }
}

.header .menu > ul > li .sub-menu > ul > li {
  line-height: 1;
}

.header .menu > ul > li .sub-menu > ul > li > a {
  display: inline-block;
  padding: 10px 0;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
  text-decoration: none;
  text-transform: capitalize;
}

.header .menu > ul > li .single-column-menu {
  min-width: 280px;
  max-width: 350px;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li {
  line-height: 1;
  display: block;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
  padding: 10px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
}

.header .menu > ul > li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
  flex: 0 0 25%;
  padding: 0 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .column {
  width: 238px;
  height: 250px;
  overflow-y: auto;
  padding: 0 10px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  line-height: 1;
  padding: 8px 0;
  background-color: #dc3558;
  text-align: center;
  border-radius: 25px;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title a {
  color: #ffffff;
}

.header
  .menu
  > ul
  > li
  .sub-menu.mega-menu-column-4
  > .list-item.text-center
  .title {
  text-align: center;
}

.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  margin-top: 10px;
  height: 300px;
  object-fit: cover;
}

.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a {
  color: #ea4636;
}

.mobile-menu-head,
.mobile-menu-trigger {
  display: none;
}

/*responsive*/
@media (max-width: 991px) {
  .header .item-center {
    order: 3;
    flex: 0 0 100%;
  }

  .header .item-left,
  .header .item-right {
    flex: 0 0 auto;
  }

  .v-center {
    justify-content: space-between;
  }

  .header .mobile-menu-trigger {
    display: flex;
    height: 30px;
    width: 30px;
    margin-left: 15px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .header .mobile-menu-trigger span {
    display: block;
    height: 2px;
    background-color: #333333;
    width: 24px;
    position: relative;
  }

  .header .mobile-menu-trigger span:before,
  .header .mobile-menu-trigger span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
  }

  .header .mobile-menu-trigger span:before {
    top: -6px;
  }

  .header .mobile-menu-trigger span:after {
    top: 6px;
  }

  .header .item-right {
    align-items: center;
  }

  .header .menu {
    position: fixed;
    width: 320px;
    background-color: #ffffff;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    transform: translate(-100%);
    transition: all 0.5s ease;
    z-index: 1099;
  }

  .header .menu.active {
    transform: translate(0%);
  }

  .header .menu > ul > li {
    line-height: 1;
    margin: 0;
    display: block;
  }

  .header .menu > ul > li > a {
    line-height: 50px;
    height: 50px;
    padding: 0 50px 0 15px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .header .menu > ul > li > a i {
    position: absolute;
    height: 50px;
    width: 50px;
    top: 0;
    right: 0;
    text-align: center;
    line-height: 50px;
    transform: rotate(-90deg);
  }

  .header .menu .mobile-menu-head {
    display: flex;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 501;
    position: sticky;
    background-color: #ffffff;
    top: 0;
  }

  .header .menu .mobile-menu-head .go-back {
    height: 50px;
    width: 50px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    display: none;
  }

  .header .menu .mobile-menu-head.active .go-back {
    display: block;
  }

  .header .menu .mobile-menu-head .current-menu-title {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .header .menu .mobile-menu-head .mobile-menu-close {
    height: 50px;
    width: 50px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    line-height: 50px;
    text-align: center;
    color: #000000;
    font-size: 25px;
  }

  .header .menu .menu-main {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header .menu > ul > li .sub-menu.mega-menu,
  .header .menu > ul > li .sub-menu {
    visibility: visible;
    opacity: 1;
    position: absolute;
    box-shadow: none;
    margin: 0;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 65px;
    max-width: none;
    min-width: auto;
    display: none;
    transform: translateX(0%);
    overflow-y: auto;
  }

  .header .menu > ul > li .sub-menu.active {
    display: block;
  }

  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideRight {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }

    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img {
    margin-top: 0;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center
    .title {
    margin-bottom: 20px;
  }

  .header
    .menu
    > ul
    > li
    .sub-menu.mega-menu-column-4
    > .list-item.text-center:last-child
    .title {
    margin-bottom: 0px;
  }

  .header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item {
    flex: 0 0 100%;
    padding: 0px;
  }

  .header .menu > ul > li .sub-menu > ul > li > a,
  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
    display: block;
  }

  .header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
    margin-bottom: 15px;
  }

  .menu-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
  }

  .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
}

/* desing scrollbar  */
.column::-webkit-scrollbar {
  width: 5px;
}

.column::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.column::-webkit-scrollbar-thumb {
  background: #dc3558;
}

.header-menu-style {
  background-color: #fff !important;
}

.sub-menu-list {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.sub-menu-list.active {
  visibility: visible;
  height: auto;
  opacity: 1;
}

/* .sub-menu-list {
  visibility: hidden;

  height: 0;

  transition: 0.3s;

  opacity: 0;
} */

/* Disable text selection */
/* Prevent text selection without disabling interactions */
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Allow button interactions */
button,
a,
input,
[role="button"] {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
  touch-action: manipulation;
}

/* Optional: Prevent image saving but allow clicks */
img {
  pointer-events: none;
  -webkit-tap-highlight-color: transparent;
}
